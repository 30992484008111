import React from "react";
import { Container } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
    container: {
        marginBottom: theme.spacing(3),
    },
    img: {
        marginBottom: 0,
    },
    credit: {
        textAlign: "right",
        paddingRight: 0,
    },
}))

const PostImage = ({ image, alt, size, children }) => {
    const classes = useStyles();
    
    const src = `/posts/${image}`;

    const containerSize = size ? size : "sm";

    return (
        <Container maxWidth={containerSize} className={classes.container}>
            <img src={src} alt={alt} className={classes.img} />
            <Container className={classes.credit}>
                {children}
            </Container>
        </Container>
    )
}

export default PostImage;