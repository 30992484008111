// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---posts-about-me-mdx": () => import("./../posts/about-me.mdx" /* webpackChunkName: "component---posts-about-me-mdx" */),
  "component---posts-manual-kubernetes-vs-openshift-mdx": () => import("./../posts/manual-kubernetes-vs-openshift.mdx" /* webpackChunkName: "component---posts-manual-kubernetes-vs-openshift-mdx" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

