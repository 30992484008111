import React from "react";
import { makeStyles } from "@material-ui/styles";
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
    divider: {
        margin: theme.spacing(2),
    },
  }));

const PostDivider = () => {
    const classes = useStyles();

    return (
        <Divider className={classes.divider} />
    )
}

export default PostDivider;